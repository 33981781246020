import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Typography, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PDFViewer from "../Global/Documents/PDFViewer";
import ImageViewer from "../Global/Documents/ImageViewer";
import { getVehicleOwnershipDocument, markVehicleDocumentAsValid, markVehicleDocumentAsInvalid } from "../../api";

const VehicleDocumentDisplay = ({ documentId, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [isRejected, setIsRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getVehicleOwnershipDocument(documentId);
                setDocument(data);
            } catch (error) {
                setError(error.message || "An error occurred while fetching the document");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [documentId]);

    const handleRotate = () => setRotationAngle((prev) => (prev + 90) % 360);
    const handleValidationChange = (event) => setIsValid(event.target.checked);
    const handleRejectionChange = (event) => {
        setIsRejected(event.target.checked);
        setIsValid(false);
    };

    const handleRejectionReasonChange = (event) => setRejectionReason(event.target.value);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (isValid) {
                await markVehicleDocumentAsValid(document.id);
            } else if (isRejected && rejectionReason) {
                await markVehicleDocumentAsInvalid(document.id, rejectionReason);
            }
        } catch (error) {
            setError(error.message || "An error occurred while submitting the document");
        } finally {
            setLoading(false);
            window.location.reload();
        }
    };

    const renderFiles = () => {
        if (!document.files || document.files.length === 0) {
            return (
                <Typography variant="body1" color="textSecondary">
                    No files available for this document.
                </Typography>
            );
        }

        return document.files.map((file, index) => {
            const fileData = `data:${file.fileType};base64,${file.fileData}`;

            return (
                <Box key={index} sx={{ mb: 2 }}>
                    {file.fileType.includes("pdf") ? (
                        <PDFViewer fileData={fileData} />
                    ) : (
                        <ImageViewer imageData={fileData} rotationAngle={rotationAngle} onRotate={handleRotate} />
                    )}
                </Box>
            );
        });
    };

    const renderContent = () => {
        if (loading) return <CircularProgress />;
        if (error) return <div>Error: {error}</div>;
        if (!document) return <div>No document data available</div>;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ flex: 1, mb: 3 }}>{renderFiles()}</Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {document.ownerClient && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            {document.ownerClient.firstName} {document.ownerClient.lastName}
                        </Typography>
                    )}
                    <FormControlLabel
                        control={<Checkbox checked={isValid} onChange={handleValidationChange} disabled={isRejected} />}
                        label="This vehicle document is valid"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isRejected} onChange={handleRejectionChange} disabled={isValid} />}
                        label="Reject this vehicle document"
                    />
                    {isRejected && (
                        <FormControl sx={{ mt: 2 }}>
                            <InputLabel id="rejection-reason-label">Reason</InputLabel>
                            <Select
                                labelId="rejection-reason-label"
                                id="rejection-reason"
                                value={rejectionReason}
                                label="Reason"
                                onChange={handleRejectionReasonChange}
                            >
                                <MenuItem value="INVALID_DOCUMENT">Invalid Document</MenuItem>
                                <MenuItem value="INVALID_DATA">Invalid Data</MenuItem>
                                <MenuItem value="EXPIRED">Expired</MenuItem>
                                <MenuItem value="BLURRED">Blurred</MenuItem>
                                <MenuItem value="OTHER">Other</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ mt: 2 }}
                        disabled={(!isValid && (!isRejected || !rejectionReason))}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper',
                boxShadow: 3,
                p: 2,
                overflowY: 'auto',
                position: 'relative',
            }}
        >
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16, color: 'gray' }}>
                <CloseIcon />
            </IconButton>
            {renderContent()}
        </Box>
    );
};

export default VehicleDocumentDisplay;
